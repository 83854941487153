import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiMonitor />,
        title: 'Desenvolvimento de Aplicações Web e Mobile',
        description: 'Criamos aplicativos personalizados, responsivos e de alta performance, otimizados para oferecer a melhor experiência ao usuário, independentemente da plataforma.'
    },
    {
        icon: <FiUsers />,
        title: 'Consultoria e Mentoria de Negócios',
        description: 'Com um foco em crescimento e estratégias de mercado, nossa consultoria ajuda a identificar oportunidades, otimizar processos e maximizar o retorno sobre o investimento.'
    },
    {
        icon: <FiCast />,
        title: 'Marketing Digital e Estratégias de Crescimento',
        description: 'Utilizamos as mais recentes técnicas de marketing digital para aumentar a visibilidade da sua marca e impulsionar o crescimento sustentável.'
    },
    { 
        icon: <FiLayers />,
        title: 'Soluções Customizadas em Tecnologia',
        description: 'Entendemos que cada negócio é único. Oferecemos soluções tecnológicas customizadas que se alinham perfeitamente com suas necessidades específicas.'
    },
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="/service-details">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
