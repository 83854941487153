import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';


class Accordion01 extends Component {
    render() {
        return (
            <Accordion className="accodion-style--1" preExpanded={'0'}>
                <AccordionItem >
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Entendimento da Demanda
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Tudo começa com uma reunião detalhada para entender as necessidades, objetivos e visão do cliente. Este é um momento crucial para estabelecer uma comunicação clara e alinhar expectativas.</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Criação do Projeto e Levantamento de Requisitos
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Com base nas informações coletadas, nossa equipe desenvolve um esboço inicial do projeto, identificando todos os requisitos técnicos e funcionais necessários.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Precificação e Estimativa de Prazo
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Apresentamos uma proposta detalhada, incluindo custos e prazos estimados, assegurando transparência total e opções flexíveis que se adaptam ao orçamento do cliente.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Assinatura do Contrato
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Com a proposta aprovada, formalizamos a parceria através da assinatura de um contrato, garantindo segurança e clareza para ambas as partes.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Desenvolvimento e Implementação
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            A fase de execução começa, com atualizações regulares para o cliente sobre o progresso. Mantemos um diálogo aberto para garantir que o projeto esteja alinhado com as expectativas.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Testes e Ajustes
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Antes da entrega final, realizamos uma série de testes rigorosos para garantir a qualidade e a funcionalidade do produto. Ajustes são feitos conforme necessário para atender a todos os requisitos.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Entrega e Treinamento
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Após a conclusão, entregamos o produto final ao cliente. Oferecemos também treinamento e suporte para garantir uma transição suave para a nova solução.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Acompanhamento Pós-Entrega
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Nosso compromisso não termina com a entrega. Oferecemos suporte contínuo e acompanhamento para assegurar a satisfação total e o desempenho ideal do produto.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

            </Accordion>
        )
    }
}

export default Accordion01;





